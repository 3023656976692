export default {
    "task": {
        "auction": "Аукцион",
        "new": "Жаңа",
        "in_work": "Жұмыста",
        "on_pause": "Тоқтатылды",
        "on_check": "Тексеруде",
        "on_rework": "Қайта жасау",
        "completed": "Аяқталды",
        "to_work": "Орындауды бастау",
        "to_pause": "Тоқтыту",
        "to_check": "Тексеруге",
        "to_rework": "Қайта жасауды",
        "to_completed": "Аяқтау",
        "to_new": "Жаңа",
        "resume": "Жандандыру",
        "today": "Бүгін",
        "task": "Тапсырма",
        "interest": "Қызығушылық",
        "task2": "Тапсырма",
        "history": "Тарих",
        "created": "Жасалды",
        "dead_line": "Соңғы мерзім",
        "priority": "Приоритет",
        "contractor": "Тапсырыс беруші",
        "organization": "Ұйым",
        "potential_contractor": "Лид",
        "potential_contractor_client": "Клиент",
        "potential_contractor_region_name": "Бизнес аймағы",
        "potential_contractor_company_name": "Ұйым",
        "operator": "Жауапты",
        "visor": "Бақылаушы",
        "project": "Жоба",
        "workgroup": "Жұмыс тобы",
        "add_subtask": "Қосымша тапсырма қосу",
        "share_to_chat": "Бөлісу",
        "copy": "Көшіру",
        "edit": "Өзгерту",
        "remove": "Жою",
        "comments": "Пікірлер",
        "main_task": "Негізгі тапсырма:",
        "main_task_message": "Чат хабарламасынан негізделген тапсырма:",
        "main_task_comment": "Пікірден негізделген тапсырма:",
        "main_task_files": "Файлдан негізделген тапсырма:",
        "main_task_order": "Тапсырыс негізінде тапсырма:",
        "add": "Қосу",
        "no_description": "Сипаттама жоқ",
        "description": "Сипаттама",
        "task_deleted": "Тапсырма жойылды",
        "error": "Қате",
        "task_not_found": "Мұндай тапсырма жоқ немесе ол жойылған",
        "ultralow": "Өте төмен",
        "low": "Төмен",
        "middle": "Орташа",
        "tall": "Жоғары",
        "veryhigh": "Өте жоғары",
        "1": "Төмен",
        "2": "Орташа",
        "3": "Жоғары",
        "owner": "Құрастырушы",
        "field_require_all": "Міндетті өрістерді толтырыңыз",
        "field_require": "Толтыру міндетті",
        "field_min_require": "Кемінде {min} символ",
        "task_created": "Тапсырма жасалды",
        "task_updated": "Тапсырма жаңартылды",
        "edit_task": "Тапсырманы өзгерту",
        "add_task": "Тапсырма қосу",
        "task_based": "Негізделген тапсырма",
        "task_name": "Тапсырманың атауы",
        "field_max_require": "Кемінде {max} символ",
        "task_desc": "Тапсырманың сипаттамасы",
        "select_performer": "Орындаушыны таңдау",
        "select_author": "Авторды таңдау",
        "observers": "Бақылаушылар",
        "select_observers": "Бақылаушыларды таңдау",
        "chat_message": "Чат хабарламалары",
        "comment": "Пікір",
        "file2": "Файл",
        "order": "Тапсырыс",
        "projects": "Жобалар",
        "additionally": "Қосымша",
        "additionally_fields": "Қосымша өрістер",
        "make_it_subtask": "Қосымша тапсырма жасау",
        "make_it_subissue": "Шағымға қатысушы",
        "subtask": "Қосымша тапсырмалар",
        "change": "Өзгерту",
        "select": "Таңдау",
        "files": "Файлдар",
        "save": "Сақтау",
        "create_and_open": "Сақтау және ашу",
        "create_and_create": "Сақтау және тағы біреуін жасау",
        "create_and_copy": "Сақтау және көшіру",
        "close": "Жабу",
        "select_task": "Тапсырманы таңдау",
        "no_time_limit": "Срок жоқ",
        "user_name": "Пайдаланушының аты",
        "select_user": "Пайдаланушыны таңдау",
        "project_name": "Жобаның атауы",
        "select_project": "Жобаны таңдау",
        "working_group_name": "Жұмыс тобының атауы",
        "select_working_group": "Жұмыс тобын таңдау",
        "select_date": "Күнді таңдау",
        "task-list-page": "Тапсырмалар",
        "task-calendar-page": "Күнтізбе",
        "task-kanban-page": "Канбан",
        "task-gantt-page": "Гант диаграммасы",
        "task-sprint-page": "Спринттер",
        "name": "Атауы",
        "status": "Статус",
        "actions": "Әрекеттер",
        "no_data": "Деректер жоқ",
        "load_more": "Тағы жүктеу",
        "create_fast_task": "Жедел тапсырма жасау",
        "tasks_not_deadline": "Соңғы мерзімі жоқ тапсырмалар",
        "week": "Апта",
        "month": "Ай",
        "quarter": "Квартал",
        "year": "Жыл",
        "day": "Күн",
        "action_disabled": "Әрекет тоқтатылды",
        "large_priority": "Жоғары приоритет",
        "very_large_priority": "Ең жоғары приоритет",
        "small_priority": "Төмен приоритет",
        "very_low_priority": "Өте төмен приоритет",
        "accounting": "Есеп",
        "participant_sprint": "Спринтке қатысушы",
        "user_not_owner": "Пайдаланушы тапсырманың иесі емес.",
        "author": "Автор",
        "date_start": "Жоспарланған шығу күні",
        "date_start_fact": "Нақты шығу күні",
        "date_start_plan": "Басталу күні",
        "date_change": "Соңғы мерзім|Басталу күні ата-аналық тапсырмаға сәйкес өзгертілді",
        "task_phone": "Телефон",
        "subject_matter": "Тақырып",
        "add_appeal": "Шағым қосу",
        "edit_appeal": "Шағымды өзгерту",
        "appeal_name": "Шағымның атауы",
        "open_task": "Тапсырманы ашу",
        "email": "Электрондық пошта",
        "organization_name": "Ұйымның атауы",
        "make_event": "Оқиға жасау"
    }
}